<template>
  <div class="print-page-sign">
    <div>
      <div>分拣员签字：</div>
    </div>
    <div>
      <div>单证员签字：</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SortingListSign',
  props: {
    printCount: {
      default: 0,
      type: [String, Number],
    },
  },
  data() {
    return {
      printTime: '',
    };
  },
  created() {
    this.printTime = this.dataFormat(new Date());
  },
  methods: {
    dataFormat(time) {
      const year = time.getFullYear();
      const month = time.getMonth() + 1;
      const day = time.getDate();
      const hours = time.getHours();
      const minutes = time.getMinutes();

      return `${year}-${this.formatTime(month)}-${this.formatTime(day)} ${this.formatTime(hours)}:${this.formatTime(minutes)}`;
    },
    formatTime(time) {
      return time < 10 ? `0${time}` : time;
    },
  },
};
</script>

 <style lang="scss" scoped>
.print-page-sign {
  margin-top: 3px;
  // border: 1px solid #000;
  & > div {
    width: 32%;
    height: 30px;
    line-height: 30px;
    display: inline-block;

    & > div {
      display: inline-block;
      height: 100%;
      width: 100px;
      text-align: center;
      // border-left: 1px solid #000;
      // border-right: 1px solid #000;
    }

    &:first-child {
      & > div {
        border-left: none;
      }
    }
  }
}
 </style>
