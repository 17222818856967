<template>
  <div class="print-page">
    <div class="print-btn">
      <PrintButton
        v-model="temperatureCode"
        :type="TEMPERATURE_LAYER_BUTTON.type"
        :temperature-list="TEMPERAYURE_LIST"
        multiple="true"
        @change="temperatureChanged"
      />
      <div class="margin-left16">
        <el-radio-group v-model="radio" class="choose-type">
          <el-radio
            v-for="item in option"
            :key="item.type"
            :label="item.type"
          >
            {{ item.title }}
          </el-radio>
        </el-radio-group>
      </div>

      <div class="margin-left16">
        <nh-button type="primary" @click="handleOpenSelectDialog">
          选择线路
        </nh-button>
      </div>
      <div v-show="radio === PATH_SHIIPER_PICK.type" class="margin-left16">
        展示分拣位
        <el-switch v-model="showSortingPosition" />
      </div>
      <select-path ref="PathDialog" v-model:select-data="selectData" />
      <div class="flex-right16">
        <nh-button type="primary" @click="handleExport">
          导出
        </nh-button>
      </div>
    </div>
    <component
      :is="TEMPLATES[radio]"
      :print-data="{...selectData, shipperId}"
      :temperature-code="temperatureCode"
      :show-sorting-position="showSortingPosition"
    >
      <template #footer>
        <div>
          <print-sign class="sign" />
        </div>
      </template>
    </component>
  </div>
</template>

<script>
import PrintButton from '../components/PrintButton/PrintButton.vue';
import PrintSign from './components/sign.vue';
import SelectPath from './components/selectPath.vue';

import PathPick from './pathPick/index.vue';
import PathShiiperPick from './pathShiiperPick/index.vue';

import { TEMPERATURE_LAYER_BUTTON } from '../components/PrintButton/config';
import TEMPLATES from './config';
import stationPick, { stationPickExport } from './api';
import {
  TEMPERAYURE_LIST, PATH_PICK, option, PATH_SHIIPER_PICK,
} from './fileds';

export default {
  components: {
    PrintButton,
    PrintSign,
    SelectPath,
    PathPick,
    PathShiiperPick,
  },
  data() {
    return {
      TEMPERATURE_LAYER_BUTTON,
      TEMPLATES,
      PATH_PICK,
      PATH_SHIIPER_PICK,
      TEMPERAYURE_LIST,
      option,
      radio: PATH_PICK.type,
      showSortingPosition: false,
      waveOrderId: null,
      temperatureCode: [],
      printData: {},
      selectData: {},
      shipperId: '',
    };
  },
  created() {
    this.waveOrderId = this.$route.query.waveOrderId;
    this.shipperId = this.$route.query.shipperId;
    this.init();
  },
  methods: {
    async init() {
      this.printData = await stationPick({
        waveOrderId: this.waveOrderId,
        temperatureLayer: this.trimSpace(JSON.parse(JSON.stringify(this.temperatureCode))).join(','),
      });
      this.selectData = this.printData;
    },
    temperatureChanged(e) {
      this.printData = {};
      this.selectData = {};
      e.forEach((item) => {
        if (item === '') {
          this.temperatureCode = ['', 'NORMAL', 'COLD', 'REFRIGERATE', 'CONSTANT'];
        }
      });

      this.init();
    },
    handleOpenSelectDialog() {
      this.$refs.PathDialog.init(this.printData);
    },
    trimSpace(array) {
      array.forEach((item, index) => {
        if (item === '' || item === null || typeof (item) === 'undefined') {
          array.splice(index, 1);
        }
      });
      return array;
    },
    getOption(list) {
      let concatList = [];
      const pathManageList = [];
      list.forEach((item) => {
        concatList = concatList.concat(item.pathGoodsDetailList);
      });
      concatList.forEach((item) => {
        const flag = pathManageList.some((path) => path.pathId === item.pathId);
        if (!flag) {
          pathManageList.push(item);
        }
      });
      return pathManageList;
    },
    handleExport() {
      const goodsBodyResList = this.selectData?.goodsBodyResList || [];
      const lineCodeList = this.getOption(goodsBodyResList)
        .map((item) => item.pathId);
      stationPickExport({
        waveOrderId: this.waveOrderId,
        waveOrderStatus: this.$route.query.waveOrderStatus,
        temperatureLayer: this.trimSpace(JSON.parse(JSON.stringify(this.temperatureCode))).join(','),
        isLine: this.radio === PATH_PICK.type, // 是否线路
        lineCodeList,
      }, '分拣单');
    },
  },
};
</script>
<style lang="scss" scoped>
.print-contanier {
  page-break-after: always;
}

.row {
  display: flex;

  .col {
    span {
      font-weight: 600;
    }
  }
}

.print-btn {
  display: flex;
  align-items: baseline;
}

.afterFooter {
  display: none;
}

.margin-left16 {
  margin-left: 16px;

  .choose-type {
    display: flex;
    align-items: center;
  }
}

.flex-right16 {
  flex: 1;
  text-align: right;
  margin-right: 24px;
}

@media print {
  .print-btn {
    display: none;
  }

  .afterFooter {
    display: block;
  }

  .sign {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
 </style>
