<template>
  <div class="print-path-shipper-pick-page">
    <div
      v-for="pathModuleIndex in pathModule.length"
      :key="pathModuleIndex"
      class="table"
    >
      <div
        v-for="tableIndex in tables.length"
        :key="tableIndex"
        class="table print-module"
      >
        <print-header
          :order="tableInfos.waveOrderNo"
          :temperature-layer="temperatureCode"
          :title="{sheetName: '分拣单'}"
          :print-config="printData"
        />
        <div class="table-content-font">
          <table
            class="pick-table"
            width="100%"
            cellpadding="0"
            cellspacing="0"
          >
            <thead>
              <table-info :table-infos="tableInfos" :way="'站点分拣'" />
              <tr>
                <th
                  nowrap
                  colspan="1"
                  rowspan="4"
                  width="26px"
                >
                  序号
                </th>
                <th
                  nowrap
                  colspan="1"
                  rowspan="4"
                  width="30px"
                >
                  温层
                </th>
                <th
                  colspan="1"
                  rowspan="4"
                  width="90px"
                >
                  货主货品编码
                </th>
                <th
                  colspan="1"
                  rowspan="4"
                  width="115px"
                >
                  货品名称
                </th>
                <th
                  v-if="!showDate"
                  colspan="1"
                  rowspan="4"
                  width="80px"
                >
                  货主名称
                </th>
                <th
                  v-if="showDate"
                  colspan="1"
                  rowspan="4"
                  width="80px"
                >
                  生产日期
                </th>
                <th
                  colspan="1"
                  rowspan="4"
                  width="55px"
                >
                  合计
                </th>
                <th
                  colspan="1"
                  rowspan="3"
                  width="40px"
                >
                  默认单位
                </th>

                <th
                  v-for="(path, pathIndex) in pathModule[pathModuleIndex-1].paths"
                  :key="pathIndex"
                  :style="setStyle({
                    maxLength: 6
                  }, path.pathName)"
                  rowspan="1"
                  class="path-name"
                  :colspan="path.shipperCount"
                >
                  {{ path.pathName }}
                </th>
              </tr>
              <tr>
                <th
                  v-for="(path, pathIndex) in pathModule[pathModuleIndex-1].shipper"
                  :key="pathIndex"
                  colspan="1"
                  rowspan="1"
                  class="shop-name-th"
                  :style="setStyle({
                    maxLength: 6
                  }, path.customerStationCode)"
                >
                  {{ path.customerStationCode }}
                </th>
              </tr>
              <tr>
                <th
                  v-for="(path, pathIndex) in pathModule[pathModuleIndex-1].shipper"
                  :key="pathIndex"
                  colspan="1"
                  rowspan="1"
                  class="shop-name-th"
                  :style="setStyle({
                    maxLength: 6
                  }, path.stationName)"
                >
                  {{ path.stationName }}
                </th>
              </tr>
              <tr>
                <th
                  :style="setStyle({
                    maxLength: pathModule[pathModuleIndex-1].shipper.length > 10 ? 2 : 3
                  }, '分拣位')"
                >
                  分拣位
                </th>
                <th
                  v-for="(path, pathIndex) in pathModule[pathModuleIndex-1].shipper"
                  :key="pathIndex"
                  colspan="1"
                  rowspan="1"
                  class="shop-name-th red-bold"
                >
                  <span v-show="showSortingPosition" class="shop-name-nth">
                    {{ path.sortingPositionIndex }}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(list, listIndex) in tables[tableIndex-1]"
                :key="listIndex"
              >
                <td>{{ list.index }}</td>
                <td>{{ list.isUnFreeze ? '解冻' : TemperatureLayerType[list.temperatureLayer] }}</td>
                <td
                  :style="list.shipperGoodsCode && setStyle({
                    maxLength: pathModule[pathModuleIndex-1].shipper.length > 10 ? 10 : 14
                  }, list.shipperGoodsCode)"
                >
                  {{ list.shipperGoodsCode }}
                </td>
                <td
                  :style="list.goodsName && setStyle({
                    maxLength: pathModule[pathModuleIndex-1].shipper.length > 10 ? 10 : 14
                  }, list.goodsName)"
                >
                  {{ list.goodsName }}
                </td>
                <td
                  v-if="!showDate"
                  :style="setStyle({
                    maxLength: 7
                  },list.shipperName)"
                >
                  {{ list.shipperName }}
                </td>
                <td
                  v-if="showDate"
                  :style="setStyle({
                    maxLength: 7
                  },list.manufactureDate)"
                >
                  {{ list.manufactureDate }}
                </td>
                <td>
                  <div :style="setStyle({maxLength: 4}, list.total)">
                    {{ list.total }}
                  </div>
                </td>
                <td
                  v-if="!list.hideDefaultUnit"
                  :rowspan="list.rowspanDefaultUnit || 1"
                  :style="list.defaultUnit && setStyle({
                    maxLength: 3
                  }, list.defaultUnit)"
                >
                  {{ list.defaultUnit }}
                </td>
                <td
                  v-for="(shipper, shipperIndex) in pathModule[pathModuleIndex-1].shipper"
                  :key="shipperIndex"
                  class="font-blod number-box"
                  :style="setNumberWidthStyle({
                    mediumLength: 4,maxLength: 6
                  }, shipper.stationId,shipper.path_pathId, list)"
                >
                  <div>{{ filterNum(shipper.stationId, shipper.path_pathId, list) }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>
<script>

import nuitNumber from '@/utils/unitNumber';
import Moment from '@/utils/moment';
import TableInfo from '../components/TableInfo.vue';
import PrintHeader from '../../components/PrintHeader/PrintHeader.vue';
import config from './config';
import { TEMPERAYURE_LIST, TemperatureLayerType } from '../fileds';
import { setWidthStyle, setNumberWidthStyle } from './common';

let sortingPositionIndex = 0;

export default {
  name: 'SortingListPathShiiperPick',
  components: {
    TableInfo,
    PrintHeader,
  },
  props: {
    temperatureCode: {
      default: '',
      type: [String, Number],
    },
    printData: {
      type: Object,
      default() {
        return {};
      },
    },
    showSortingPosition: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TemperatureLayerType,
      TEMPERAYURE_LIST,
      config,
      goodsIds: [],
      tables: [],
      pathModule: [],
      tableInfos: {},
      pathIds: [],
      moduleCount: 0,
      total: {
        bigUnitPick: 0,
        smallUnitPick: 0,
        auxiliaryNum: 0,
      },
      shipperName: '食其家',
      form: {},
      printTime: '',
      tableList: [],
      timer: null,
      isLoading: false,
      showDate: false,
      paths: [],
    };
  },

  watch: {
    printData: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.showDate = this.$route.query.waveOrderStatus === 'PICK_COMPLETED';
      const { header = {}, goodsBodyResList = [] } = this.printData;
      this.tableInfos = header;
      const concatList = [];
      goodsBodyResList.forEach((list) => {
        list.pathGoodsDetailList.forEach((item) => {
          const { stationGoodsDetailList = [], ...path } = item;
          const newPath = {};
          Object.keys(path).forEach((key) => {
            newPath[`path_${key}`] = path[key];
          });
          stationGoodsDetailList.forEach((station) => {
            const flag = concatList.some((concatItem) => (
              concatItem.path_pathId === newPath.path_pathId
            && concatItem.stationId === station.stationId));
            if (!flag) {
              concatList.push({ ...station, ...newPath });
            }
          });
        });
      });
      concatList.sort((a, b) => a.path_pathId.localeCompare(b.path_pathId));
      this.pathModule = this.rebuildPaths(concatList);

      this.getTableList(goodsBodyResList);
    },
    getTableList(goodsBodyResList) {
      this.tableList = goodsBodyResList.map((item) => ({
        ...item,
        total: item.pickNum ? nuitNumber.calculate({ ...item, smallUnitNumber: item.pickNum }).txt : '',
      }));
      this.setTables();
    },
    // 转默认单位数值
    conversionUnit(total, unit) {
      if (unit.defaultUnit === unit.smallUnit) {
        return total;
      }
      return this.isInteger(((total / unit.conversionRate) * 1000000) / 1000000);
    },
    // 合并站点
    mergeStation(newStation, station, unit) {
      const stationList = [...newStation];
      station.forEach((item) => {
        const findIndex = newStation
          .findIndex((list) => list.stationId === item.stationId);
        if (findIndex >= 0) {
          stationList[findIndex]
            .totalPick = (
              this.conversionUnit((item.totalPick || 0), unit) * 1000
              + stationList[findIndex].totalPick * 1000
            ) / 1000;
        } else {
          stationList.push({
            ...item,
            totalPick: this.conversionUnit((item.totalPick || 0), unit),
          });
        }
      });
      return stationList;
    },
    setTables() {
      let tablePage = 0;
      const tableRowsCount = config.maxRowsNumber;
      this.tables = [];
      const totalLists = []; // 门店合计列
      this.tableList.forEach((item, index) => {
        const unit = {
          smallUnit: item.smallUnit,
          bigUnit: item.bigUnit,
          defaultUnit: item.defaultUnit,
          conversionRate: item.conversionRate || 1,
        };
        // 计算门店合计
        item.pathGoodsDetailList.forEach((path) => {
          const { stationGoodsDetailList = [], ...pathProp } = path;
          const findIndex = totalLists
            .findIndex((list) => list.pathId === pathProp.pathId);
          if (findIndex >= 0) {
            totalLists[findIndex].stationGoodsDetailList = this.mergeStation(
              totalLists[findIndex].stationGoodsDetailList,
              stationGoodsDetailList,
              unit,
            );
          } else {
            totalLists.push({
              ...pathProp,
              stationGoodsDetailList: this.mergeStation([], stationGoodsDetailList, unit),
            });
          }
        });
        const list = {
          ...item,
          index: index + 1,
          manufactureDate: Moment.format(item.manufactureDate, 'YYYY-MM-DD'),
        };
        if (index === (tableRowsCount * tablePage)) {
          tablePage += 1;
          this.tables.push([]);
        }
        this.tables[tablePage - 1].push(list);
      });
      this.tables[this.tables.length - 1].push({
        defaultUnit: '合计',
        smallUnit: '合计',
        pathGoodsDetailList: totalLists,
      });
    },
    rebuildPaths(paths) {
      let length = 0;
      const pathModule = [];

      let pathId = '';
      let pathName = '';
      let samePathNameCount = 1;

      paths.forEach((item, index) => {
        const path = {
          ...item,
        };
        // 多个门店拥有同一条线路时，合并线路
        if (pathId !== path.path_pathId || index === (length * config.maxPathsNumber)) {
          if (index > 0) {
            pathModule[length - 1].paths.push({
              pathId,
              pathName,
              shipperCount: samePathNameCount,
            });
          }
          pathId = path.path_pathId;
          pathName = path.path_pathName;
          samePathNameCount = 0;
        }
        samePathNameCount += 1;
        sortingPositionIndex += 1;

        if (index === (length * config.maxPathsNumber)) {
          length += 1;
          pathModule.push({
            paths: [],
            shipper: [],
          });
        }

        // 最后组路线时，记录门店数量
        if (index === paths.length - 1) {
          pathModule[length - 1].paths.push({
            pathId: path.path_pathId,
            pathName: path.path_pathName,
            shipperCount: samePathNameCount,
          });
        }

        pathModule[length - 1].shipper.push({ ...path, sortingPositionIndex: `-${sortingPositionIndex}-` });
      });
      return pathModule;
    },
    isShowSpaceTd(tableIndex, listIndex) {
      let isShow = false;
      const currentTable = this.tables[tableIndex - 1];
      const currentTableLength = currentTable.length;

      // 如果当前页是最后一页
      if (this.isLastTable(tableIndex)) {
        if (currentTableLength < 6) {
          isShow = listIndex < currentTableLength - 1;
        } else {
          isShow = listIndex < currentTableLength - 1 && listIndex > currentTableLength - 7;
        }
      } else if (
        this.tables.length - 1 === tableIndex
        && this.tables[this.tables.length - 1].length < 6
      ) {
        // 如果当前页是倒数第二页，且倒数第一页的行数少于6
        // isShow = true
        isShow = listIndex >= currentTableLength - (6 - this.tables[this.tables.length - 1].length);
      }

      return isShow;
      // return isShow
    },
    isLastTable(tableIndex) {
      return this.tables.length === tableIndex;
    },
    setStyle(option, value) {
      const widthStyle = setWidthStyle(option, value);
      return { ...widthStyle };
    },
    setNumberWidthStyle(option, id, pathId, lists) {
      const list = lists?.pathGoodsDetailList?.find((item) => item.pathId === pathId);
      const station = list?.stationGoodsDetailList.find((item) => item.stationId === id);
      let defaultUnitNum = 0;
      if (station) {
        if (lists.defaultUnit === lists.smallUnit) {
          defaultUnitNum = station.totalPick;
        }
        defaultUnitNum = Math.round((station.totalPick / lists.conversionRate) * 100) / 100;
      }
      const widthStyle = setNumberWidthStyle(option, defaultUnitNum || 0);
      return { ...widthStyle };
    },
    filterNum(id, pathId, lists) {
      // 相同线路和门店 数据合并
      let total = 0;
      const list = lists?.pathGoodsDetailList?.filter((item) => item.pathId === pathId);
      list.forEach((item) => {
        item.stationGoodsDetailList.forEach((station) => {
          if (station.stationId === id) {
            total = (this.isInteger(station.totalPick * 100000) + (total * 100000)) / 100000;
          }
        });
      });

      if (!total) {
        return '';
      }

      if (lists.defaultUnit === lists.smallUnit) {
        return this.isInteger(total);
      }

      return this.isInteger(((total / lists.conversionRate) * 100000) / 100000);
    },
    isInteger(num) {
      const x = String(num).indexOf('.') + 1;// 得到小数点的位置
      const y = String(num).length - x;// 小数点的位数
      if (num % 1 === 0 || y <= 3) {
        return num;
      }

      return num.toFixed(3);
    },
  },
};
</script>

<style lang="scss" scoped>
.print-path-shipper-pick-page {
  font-size: 14px;
  color: #000;
}

table {
  thead {
    th {
      padding: 0;
    }

    .path-name {
      height: 30px;
    }

    .shop-name-th {
      height: 30px;
      width: 58px;
      line-height: 14px;
      font-weight: normal;
      background-color: #fff;
    }

    .red-bold {
      font-weight: 600;
      color: #ff001e;
    }
  }

  tbody {
    td {
      height: 27px;
      line-height: 14px;
    }

    .number-box {
      font-size: 16px;
    }

    .font-blod {
      font-weight: 700;
    }
  }
}

.shop-name-nth {
  font-size: 22px;
}

.pick-table {
  text-align: center;
  border-collapse: collapse;

  th {
    padding: 2px 0;
    background-color: #f5f7fa;
    border-top: 1px solid #000;
  }

  tr {
    height: 26px;
  }
}

.pick-table td,
.pick-table th {
  word-break: break-all;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;

  &:first-of-type {
    border-left: 1px solid #000;
  }
}

.print-module {
  page-break-after: always;
}

@media print {
  .table-content {
    height: 654px;

    table {
      max-height: 100%;
    }
  }
}

.table {
  page-break-after: always;
}
</style>
