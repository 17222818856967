<template>
  <nh-dialog
    v-model="dialogVisible"
    title="打印分拣单"
    @close="handleClose"
  >
    <PlTable
      ref="table"
      :data="tableData"
      :columns="TABLECOLUMN"
      max-height="400px"
      @selection-change="handleSelect"
    />
    <template #footer>
      <div class="footer">
        <div class="footer-count">
          共 {{ tableData.length }} 条线路，已选 {{ selectPath.length }} 条
        </div>
        <div>
          <nh-button @click="handleConfirm">
            确 定
          </nh-button>
          <nh-button @click="onClose">
            取 消
          </nh-button>
        </div>
      </div>
    </template>
  </nh-dialog>
</template>
<script>
import nuitNumber from '@/utils/unitNumber';
import TABLECOLUMN from './constant';

export default {
  name: 'PrintSortingListSelectPath',
  props: {
    selectData: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update:selectData'],
  data() {
    return {
      dialogVisible: false,
      TABLECOLUMN,
      initPathData: {},
      selectPath: [], // 选中线路
      tableData: [], // 所有线路
    };
  },
  methods: {
    async init(data = {}) {
      this.dialogVisible = true;
      this.initPathData = data;
      const { goodsBodyResList = [] } = data;
      const { goodsBodyResList: selectList = [] } = this.selectData;
      this.tableData = this.getOption(goodsBodyResList);
      this.selectPath = this.getOption(selectList).map((item) => item.pathId);
      this.$nextTick(() => {
        this.setSelect();
      });
    },
    getOption(list) {
      let concatList = [];
      const pathManageList = [];
      list.forEach((item) => {
        concatList = concatList.concat(item.pathGoodsDetailList);
      });
      concatList.forEach((item) => {
        const flag = pathManageList.some((path) => path.pathId === item.pathId);
        if (!flag) {
          pathManageList.push(item);
        }
      });
      return pathManageList;
    },
    setSelect() {
      this.selectPath.forEach((pathId) => {
        const index = this.tableData.findIndex(((item) => item.pathId === pathId));
        this.$refs.table.elTable.toggleRowSelection(this.tableData[index]);
      });
    },
    handleSelect(select) {
      this.selectPath = select.map((item) => item.pathId);
    },
    getPickNum(list, config) {
      let total = 0;
      list.forEach((item) => {
        total += Number(nuitNumber.getTotal({
          ...config,
          smallUnitNumber: item.smallUnitPick,
          bigUnitNumber: item.bigUnitPick,
        }));
      });
      return total;
    },
    handleConfirm() {
      const { goodsBodyResList = [] } = this.initPathData;
      const updateList = goodsBodyResList.map((list) => {
        const path = [];
        if (!list.pathGoodsDetailList || !Array.isArray(list.pathGoodsDetailList)) {
          return {
            ...list,
            pathGoodsDetailList: path,
            pickNum: null,
          };
        }
        list.pathGoodsDetailList.forEach((item) => {
          const flag = this.selectPath.find((id) => item.pathId === id);
          if (flag) {
            path.push(item);
          }
        });
        return {
          ...list,
          pathGoodsDetailList: path,
          pickNum: this.getPickNum(path, {
            conversionRate: list.conversionRate,
          }),
        };
      });
      const deleteAfterList = this.deleteList(updateList);
      this.$emit('update:selectData', {
        ...this.initPathData,
        goodsBodyResList: deleteAfterList,
      });
      this.onClose();
    },
    // 移除线路全为空的货品
    deleteList(list) {
      return list.filter((item) => item.pathGoodsDetailList.length > 0
      && item.pathGoodsDetailList.some((path) => !!path.totalPick));
    },
    onClose() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.$refs.table.elTable.clearSelection();
    },
  },
};
</script>
<style scoped lang="scss">
.footer {
  display: flex;
  justify-content: space-between;
}
</style>
