const TemperatureLayerType = {
  NORMAL: '常温',
  COLD: '冷冻',
  REFRIGERATE: '冷藏',
  CONSTANT: '恒温',
};
const TEMPERAYURE_LIST = [{
  temperatureLayerCode: '',
  temperatureLayerName: '全部',
}, {
  temperatureLayerCode: 'NORMAL',
  temperatureLayerName: '常温',
}, {
  temperatureLayerCode: 'COLD',
  temperatureLayerName: '冷冻',
}, {
  temperatureLayerCode: 'REFRIGERATE',
  temperatureLayerName: '冷藏',
}, {
  temperatureLayerCode: 'CONSTANT',
  temperatureLayerName: '恒温',
}];

const PATH_PICK = {
  type: 'PATH_PICK',
  title: '线路',
};
const PATH_SHIIPER_PICK = {
  type: 'PATH_SHIIPER_PICK',
  title: '站点',
};
const option = [PATH_PICK, PATH_SHIIPER_PICK];
export {
  TemperatureLayerType,
  TEMPERAYURE_LIST,
  PATH_PICK,
  PATH_SHIIPER_PICK,
  option,
};
