<template>
  <tr class="print-table-infos">
    <th colspan="100%">
      <div>
        货主: {{ ListtoString(tableInfos.customerNameList) }}
      </div>
      <div>
        分拣方式: {{ way }}
      </div>
      <div>
        波次单号: {{ tableInfos.waveOrderNo }}<el-button
          v-if="tableInfos.urgent"
          type="danger"
          round
          size="mini"
          style="margin-left: 10px;"
        >
          加急
        </el-button>
      </div>
      <div>
        最早提货时间: {{ filterMonent(tableInfos.earliestTakeDeliveryTime) }}
      </div>
    </th>
  </tr>
</template>

<script>
import moment from 'moment';
import { YMDHMS } from '@/constant/timeFormat';

export default {
  props: {
    way: {
      type: String,
      default: '',
    },
    tableInfos: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    ListtoString(arr) {
      return arr && arr.join('，');
    },
    filterMonent(val) {
      return val && moment(val).format(YMDHMS);
    },
  },
};
</script>
<style scoped lang="scss">
.print-table-infos {
  th {
    font-size: 14px;
    font-weight: 400;
    height: 40px;

    & > div {
      display: inline-block;
      text-align: left;
    }

    & > div:first-of-type {
      width: 20%;
    }

    & > div:nth-of-type(2) {
      width: 18%;
    }

    & > div:nth-of-type(3) {
      width: 30%;
    }

    & > div:last-of-type {
      width: 32%;
    }
  }
}
</style>
