import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:波次单打印分拣
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12500
 */
export default function stationPick(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order_print/station_pick', data);
}

/**
 * @description:导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/20627
 */
export function stationPickExport(data, fileName) {
  return wmsPlusHttp.download('/warehouse_management_system/wave_order_print/station_pick/export', data, { fileName, extension: 'xlsx' });
}
