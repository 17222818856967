export default [
  {
    type: 'selection',
    width: 50,
  },
  {
    label: '序号',
    type: 'index',
    width: 50,
  },
  {
    label: '线路编号',
    prop: 'pathId',
    minWidth: 100,
  },
  {
    label: '线路名称',
    prop: 'pathName',
    minWidth: 150,
  },
];
