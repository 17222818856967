<template>
  <div class="print-total-pick-page">
    <div
      v-for="pathModuleIndex in pathModule.length"
      :key="pathModuleIndex"
      class="table"
    >
      <div
        v-for="tableIndex in tables.length"
        :key="tableIndex"
        class="table print-module"
      >
        <print-header
          :order="tableInfos.waveOrderNo"
          :temperature-layer="temperatureCode"
          :title="{sheetName: '分拣单'}"
          :print-config="printData"
        />
        <div
          class="table-content-font"
          :class="{
            'table-content': tableIndex !== tables.length
          }"
        >
          <table
            class="pick-table"
            width="100%"
            cellpadding="0"
            cellspacing="0"
          >
            <thead>
              <table-info :table-infos="tableInfos" :way="'线路分拣'" />
              <tr class="print-table-title">
                <th
                  colspan="1"
                  rowspan="1"
                  width="30px"
                >
                  序号
                </th>
                <th
                  colspan="1"
                  rowspan="1"
                  width="60px"
                >
                  温层
                </th>
                <th
                  noWrap
                  colspan="1"
                  rowspan="1"
                  width="120px"
                >
                  货主货品编码
                </th>
                <th
                  colspan="1"
                  rowspan="1"
                  width="130px"
                >
                  货品名称
                </th>
                <th
                  v-if="!showDate"
                  colspan="1"
                  rowspan="1"
                  width="80px"
                >
                  货主名称
                </th>
                <th
                  v-if="showDate"
                  colspan="1"
                  rowspan="1"
                  width="80px"
                >
                  生产日期
                </th>
                <th
                  noWrap
                  colspan="1"
                  rowspan="1"
                  width="120px"
                >
                  合计
                </th>
                <th
                  colspan="1"
                  rowspan="1"
                  width="80px"
                >
                  默认单位
                </th>

                <th
                  v-for="(path, pathIndex) in pathModule[pathModuleIndex-1]"
                  :key="pathIndex"
                  colspan="1"
                  rowspan="1"
                  width="80px"
                  :style="setStyle({
                    maxLength: 6
                  }, path.pathName)"
                >
                  {{ path.pathName }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(list, listIndex) in tables[tableIndex-1]"
                :key="listIndex"
              >
                <td>{{ list.index }}</td>
                <td>{{ TemperatureLayerType[list.temperatureLayer] }}</td>
                <td>{{ list.shipperGoodsCode }}</td>
                <td
                  :style="setStyle({
                    maxLength: pathModule[pathModuleIndex-1].length > 10 ? 10 : 20
                  }, list.goodsName)"
                >
                  {{ list.goodsName }}
                </td>
                <td
                  v-if="!showDate"
                  :style="setStyle({
                    maxLength: pathModule[pathModuleIndex-1].length > 10 ? 10 : 20
                  }, list.shipperName)"
                >
                  {{ list.shipperName }}
                </td>
                <td
                  v-if="showDate"
                  :style="setStyle({
                    maxLength: 7
                  },list.manufactureDate)"
                >
                  {{ list.manufactureDate }}
                </td>
                <td>
                  <div :style="setNumberStyle({maxLength: 6}, list)">
                    {{ calculateNum(list.pickNum, list) }}
                  </div>
                </td>
                <td
                  v-if="!list.hide"
                  :rowspan="list.rowspan || 1"
                >
                  <div>{{ list.defaultUnit }}</div>
                </td>

                <td
                  v-for="(path, i) in pathModule[pathModuleIndex-1]"
                  :key="listIndex + i"
                  class="font-blod number-box letter-spacing-1"
                  :style="setNumberWidthStyle({mediumLength: 4,maxLength: 6}, path.pathId, list)"
                >
                  {{ filterNum(path.pathId, list) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>
<script>
import nuitNumber from '@/utils/unitNumber';
import Moment from '@/utils/moment';
import TableInfo from '../components/TableInfo.vue';
import PrintHeader from '../../components/PrintHeader/PrintHeader.vue';
import config from './config';
import { TemperatureLayerType } from '../fileds';
import { setWidthStyle, setNumberWidthStyle } from './common';

export default {
  name: 'SortingListPathPick',
  components: {
    TableInfo,
    PrintHeader,
  },
  props: {
    temperatureCode: {
      default: '',
      type: [String, Number],
    },
    printData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      nuitNumber,
      config,
      TemperatureLayerType,
      tableInfos: {},
      tables: [],
      pathModule: [],
      tableList: [],
      showDate: false,
    };
  },
  watch: {
    printData: {
      handler(val) {
        if (val) {
          this.init();
        }
      },
      deep: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.showDate = this.$route.query.waveOrderStatus === 'PICK_COMPLETED';
      const { header = {}, goodsBodyResList = [] } = this.printData;
      this.tableInfos = header;
      let concatList = [];
      goodsBodyResList.forEach((item) => {
        concatList = concatList.concat(item.pathGoodsDetailList);
      });
      const pathManageList = [];
      concatList.forEach((item) => {
        const flag = pathManageList.some((path) => path.pathId === item.pathId);
        if (!flag) {
          pathManageList.push(item);
        }
      });
      this.tableList = goodsBodyResList;
      this.pathModule = this.rebuildPaths(pathManageList);
      this.setTables();
    },
    filterNum(id, lists) {
      const list = lists?.pathGoodsDetailList?.find((item) => item.pathId === id);
      if (list) {
        if (lists.defaultUnit === lists.smallUnit) {
          return list.totalPick == null ? '' : this.isInteger(list.totalPick);
        }
        return list.totalPick == null ? '' : this.isInteger(((list.totalPick / lists.conversionRate) * 10000000) / 10000000);
      }
      return '';
    },
    calculateNum(num, list) {
      if (num) {
        return list && nuitNumber.calculate({ ...list, smallUnitNumber: num }).txt;
      }
      return '';
    },
    setTables() {
      let tablePage = 0;
      const tableRowsCount = config.maxRowsNumber;
      this.tables = [];
      this.tableList.forEach((item, index) => {
        const list = {
          ...item,
          index: index + 1,
          manufactureDate: Moment.format(item.manufactureDate, 'YYYY-MM-DD'),
        };
        if (index === (tableRowsCount * tablePage)) {
          tablePage += 1;
          this.tables.push([]);
        }
        this.tables[tablePage - 1].push(list);
      });
    },
    rebuildPaths(paths) {
      let length = 0;
      const pathModule = [];

      paths.forEach((item, index) => {
        const path = {
          ...item,
        };
        if (index === (length * config.maxPathsNumber)) {
          length += 1;
          pathModule.push([]);
        }
        pathModule[length - 1].push(path);
      });

      return pathModule;
    },
    setStyle(option, txt) {
      const widthStyle = setWidthStyle(option, txt || '');
      return { ...widthStyle };
    },
    setNumberStyle(option, list) {
      const unitTxt = nuitNumber.calculate({ ...list, smallUnitNumber: list.pickNum }).txt;
      const widthStyle = setWidthStyle(option, unitTxt || '');
      return { ...widthStyle };
    },
    setNumberWidthStyle(option, id, lists) {
      const list = lists?.pathGoodsDetailList?.find((item) => item.pathId === id);
      let defaultUnitNum = 0;
      if (list) {
        if (lists.defaultUnit === lists.smallUnit) {
          defaultUnitNum = list.totalPick;
        }
        defaultUnitNum = Math.round((list.totalPick / lists.conversionRate) * 100) / 100;
      }
      const widthStyle = setNumberWidthStyle(option, defaultUnitNum || 0);
      return { ...widthStyle };
    },
    isInteger(num) {
      const x = String(num).indexOf('.') + 1;// 得到小数点的位置
      const y = String(num).length - x;// 小数点的位数
      if (num % 1 === 0 || y <= 3) {
        return num;
      }

      return num.toFixed(3);
    },
  },
};
</script>

<style lang="scss" scoped>
.pick-table {
  text-align: center;
  border-collapse: collapse;

  tr {
    height: 26px;
  }

  th {
    font-size: 14px;
    padding: 2px 0;
    background-color: #f5f7fa;
    border-top: 1px solid #000;
    word-break: break-all;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;

    &:first-of-type {
      border-left: 1px solid #000;
    }
  }
}

.pick-table td {
  word-break: break-all;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}

.print-total-pick-page {
  font-size: 14px;
  color: #000;

  table {
    tbody {
      td {
        line-height: 14px;
      }

      .font-blod {
        font-weight: 700;
      }
    }
  }
}

.pick-table td:first-of-type {
  border-left: 1px solid #000;
}

.table-content-font {
  font-size: 14px;
  font-weight: 400;
}

.print-module {
  page-break-after: always;
}

@media print {
  .table-content {
    height: 654px;

    table {
      max-height: 100%;
    }
  }
}

.table {
  page-break-after: always;
}
</style>
