// 设置宽度
const setWidthStyle = (option, val) => {
  const style = {};
  let value = val;
  if (!value) {
    return style;
  } if (typeof value === 'number') {
    value = value.toString();
  }

  if (option.maxLength && value.length > option.maxLength) {
    // 如果字符串超过最大长度，缩小字号
    style.fontSize = '12px';
    style.lineHeight = '12px';
  } else if (option.mediumLength && value.length > option.mediumLength) {
    // 如果字符串超过最大长度，缩小字号
    style.fontSize = '13px';
    style.lineHeight = '13px';
  }

  return style;
};

// 设置宽度
const setNumberWidthStyle = (option, val) => {
  const style = {};
  let value = val;

  if (!value) {
    return style;
  } if (typeof value === 'number') {
    value = value.toString();
  }

  if (option.maxLength && value.length > option.maxLength) {
    // 如果字符串超过最大长度，缩小字号
    style.fontSize = '14px';
  } else if (option.mediumLength && value.length > option.mediumLength) {
    // 如果字符串超过最大长度，缩小字号
    style.fontSize = '16px';
  }

  return style;
};

// 设置宽度
const splitInternationalCode = (internationalCode) => {
  if (!internationalCode) {
    return '';
  }

  return internationalCode.replace(/,/g, ';<br/>');
};

export {
  setWidthStyle,
  setNumberWidthStyle,
  splitInternationalCode,
};
